import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
    font-family: 'PT Sans Caption', sans-serif;
    font-size: ${props => props.size}px;
    color: #fff;
    font-weight: 700;
    margin-top: 0;

    a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export default ({ children, size }) => <Text size={size}>{children}</Text>;
