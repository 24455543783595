import React from 'react';

import SEO from '../components/seo';
import Header from '../components/header';
import styled from 'styled-components';
import logo from '../images/logo.svg';
import Text from '../components/text';

const Logotype = styled.img`
    width: 80%;
    height: auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
`;

const Heading = styled.h1`
    display: flex;
    justify-content: center;
    position: relative;
    top: -5rem;
`;

const IndexPage = () => {
    return (
        <>
            <SEO title="Home" lang="sv" />
            <Header>
                <Heading>
                    <Logotype src={logo} alt="Stuvsta bygg & entreprenad" />
                </Heading>
                <Text size="28">
                    <a href="tel:070-5333133">073-533 31 33</a>
                </Text>
                {/* <Text size="28">
                    <a href="mailto:jonas@styvstabygg.se">
                        jonas@styvstabygg.se
                    </a>
                </Text> */}
            </Header>
        </>
    );
};

export default IndexPage;
