import React from 'react';
import styled from 'styled-components';
import Hero from '../images/Hero.png';

const Header = styled.header.attrs({
    style: {
        backgroundImage: `url(${Hero})`,
    },
})`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    min-height: 100vh;
`;

export default ({ children }) => <Header>{children}</Header>;
